import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//获取岗亭人员列表
export function getGuardsList(params) {
    return request.get('/sentryBoxController/getGuardsList?' + objectToQueryString(params))
}

//离职
export function resign(id) {
    return request.post('/sentryBoxController/resign?id=' + id)
}

//添加或编辑
export function saveOrUpdateGuards(body) {
    return request.post('/sentryBoxController/saveOrUpdateGuards', body)
}
