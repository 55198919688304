<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="parkingLotId">
          <SuggestParking v-model="query.parkingLotId" style="width: 260px"/>
        </el-form-item>

        <el-form-item prop="workStatus">
          <el-select
              v-model="query.workStatus"
              clearable
              placeholder="请选择工作状态"
              style="width: 260px"
          >
            <el-option label="在职" value="0"></el-option>
            <el-option label="离职" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="phoneNum">
          <el-input
              v-model="query.phoneNum"
              clearable
              placeholder="手机号码搜索"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('001003004001')"
              icon="add-select"
              title="添加"
              @click.native="addPerson"
          />
          <icon-button
              v-if="hasRights('001003004004')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>

      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="人员编号"
            prop="userCode"
            width="200"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="人员姓名"
            prop="userName"
            width="200"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="手机号（账号）"
            prop="userPhone"
            width="200"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="入职时间"
            prop="onboardingDate"
            width="240"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="离职时间"
            prop="resignDate"
            width="240"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="最后登录时间"
            prop="lastLoginDate"
            width="240"
        >
        </el-table-column>
        <el-table-column align="center" label="状态" prop="workStatus">
          <template slot-scope="scope">
            {{ scope.row.workStatus === "0" ? "在职" : "离职" }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="240px"
        >
          <template v-if="scope.row.workStatus === '0'" slot-scope="scope">
            <el-button
                v-if="hasRights('001003004002')"
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="edit(scope.row)"
            >编辑
            </el-button
            >
            <el-button
                v-if="hasRights('001003004003')"
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="doQuit(scope.row.id)"
            >离职
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 15, 30, 50]"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="exportForm" :model="exportForm" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="exportForm.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>

    <!-- 添加人员弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="beforeAddPersonDialogClosed"
        :title="title"
        :visible.sync="addPersonDialogFormVisible"
        class="common-dialog"
        width="800px"
    >
      <el-form
          ref="form"
          v-loading="isAddLoading"
          :model="form"
          :rules="rules"
          class="label-weight"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
                label="选择结算方"
                label-width="120px"
                prop="settlementId"
            >
              <Settlement
                  v-model="form.settlementId"
                  :disabled="form.id !== ''"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="选择停车场"
                label-width="120px"
                prop="parkingLotIds"
            >
              <el-select
                  v-model="form.parkingLotIds"
                  :disabled="form.id !== ''"
                  class="select-inner"
                  multiple
                  placeholder="请选择停车场"
                  style="width: 260px"
              >
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.parkingLotName"
                    :value="item.id"
                ></el-option>
              </el-select>
              <!-- <SuggestParking
                v-model="form.parkingLotIds"
                :multiple= "true"
              /> -->
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号码" label-width="120px" prop="phoneNum">
              <el-input
                  v-model="form.phoneNum"
                  :disabled="form.id !== ''"
                  placeholder="请填写手机号码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" label-width="120px" prop="name">
              <el-input
                  v-model="form.name"
                  :disabled="form.id !== ''"
                  placeholder="禁止昵称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="输入密码" label-width="120px" prop="password">
              <el-input
                  v-model="form.password"
                  placeholder="请填写密码"
                  show-password
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="确认密码"
                label-width="120px"
                prop="confirmPassword"
            >
              <el-input
                  v-model="form.confirmPassword"
                  placeholder="请确认密码"
                  show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="closed">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import SuggestParking from "@/components/SuggestParking";
import Settlement from "@/components/Settlement";
import {getGuardsList, resign, saveOrUpdateGuards} from "@/api/sentrybox";
import {findParkingBySettlementId} from "@/api/settlementManage";
import {doExport} from "@/api/common";

export default {
  name: "person_manage",
  mixins: [BaseMixin, Permission],
  components: {
    SuggestParking,
    Settlement,
  },
  data() {
    return {
      id: "", //当前人员的id
      editDisabledInput: false, //判断是否编辑，编辑禁止修改选项
      title: "", //编辑添加弹窗标题
      addPersonDialogFormVisible: false, //添加人员弹窗
      isAddLoading: false, //添加编辑的loading
      form: {
        id: "",
        phoneNum: "",
        name: "",
        password: "",
        confirmPassword: "",
        parkingLotIds: [],
        settlementId: "",
      },
      rules: {
        phoneNum: [{required: true, message: "手机号码不能为空"}],
        name: [{required: true, message: "真实姓名不能为空"}],
        parkingLotIds: [{required: true, message: "请选择停车场"}],
        settlementId: [{required: true, message: "请选择结算方"}],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("密码不能为空"));
              } else if (
                  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(value)
              ) {
                callback(
                    new Error(
                        "必须包含大小写字母和数字的组合,可以使用特殊字符,长度在8-16之间"
                    )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("确认密码不能为空"));
              } else if (value !== this.form.password) {
                callback(new Error("两次密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      query: {
        parkingLotId: "", //停车场id
        workStatus: "0", //工作状态   0在职  1离职
        phoneNum: "", //手机号
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      dataList: [],
      options: [], //车场列表选项
      exportDialogFormVisible: false, //导出弹窗
      exportForm: {
        exportSize: 1,
      },
    };
  },
  //监听选中的结算方，查询当前结算方关联车场
  watch: {
    "form.settlementId"(newVal) {
      const pageSize = 9999;
      const pageNo = 1;
      const id = newVal;

      // this.form.parkingLotIds = [];
      findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject.list;
        }
      });
    },
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      const params = this.paramFormat(this.query);
      const res = await getGuardsList(params);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.exportForm.exportSize = returnObject.total;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.exportForm.exportSize;
      // window.open("http://172.16.69.129:81" + "/sentryBoxController/getGuardsList?" + objectToQueryString(params));
      doExport("/sentryBoxController/getGuardsList", params, "岗亭人员.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //办理离职
    doQuit(id) {
      this.$confirm("确定要给该人员办理离职吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        resign(id).then((res) => {
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("办理离职成功！");
              this.doSearch();
            }
          }, 2000);
        });
      });
    },
    //添加岗亭人员
    addPerson() {
      this.form.id = "";
      this.form.phoneNum = "";
      this.form.name = "";
      this.form.settlementId = "";
      this.form.parkingLotIds = [];
      this.form.password = "";
      this.form.confirmPassword = "";
      this.title = "添加人员";
      this.addPersonDialogFormVisible = true;
    },
    //关闭弹窗清理表单
    beforeAddPersonDialogClosed(done) {
      this.$refs.form.resetFields();
      done();
    },
    //编辑人员
    edit(e) {
      this.addPersonDialogFormVisible = true;

      setTimeout(() => {
        this.title = "编辑人员";
        this.form.id = e.id;
        this.form.phoneNum = e.userPhone;
        this.form.name = e.userName;
        this.form.settlementId = e.settlementId;

        // findParkingBySettlementId(e.settlementId, 100000, 1).then((res) => {
        //   if (res && res.code === 30 && res.result) {
        //     this.options = res.returnObject.list;
        //     this.form.parkingLotIds = eval("(" + e.parkingLotIds + ")");
        //   }
        // });
        this.form.parkingLotIds = eval("(" + e.parkingLotIds + ")");
        // this.form.parkingLotIds = JSON.parse(e.parkingLotIds);
        // console.log(this.form.parkingLotIds);

        this.form.password = e.password;
        this.form.confirmPassword = e.confirmPassword;
      }, 100);
    },
    //关闭添加
    closed() {
      this.addPersonDialogFormVisible = false;
      this.$refs.form.resetFields();
    },
    //保存
    save() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }

        this.isAddLoading = true;
        const res = await saveOrUpdateGuards(this.form);

        setTimeout(() => {
          this.isAddLoading = false;
          if (res && res.code === 30 && res.result) {
            this.$message.success("保存成功！");
            this.addPersonDialogFormVisible = false;
            this.$refs.form.resetFields();
            this.doSearch();
          }
        }, 2000);

        // this.isAddLoading = false;
        // if (res && res.code === 30 && res.result) {
        //   this.$message.success("保存成功！");
        //   this.addPersonDialogFormVisible = false;
        //   this.$refs.form.resetFields();
        //   setTimeout(this.search(), 2000);
        // }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
